import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Success = () => {
  const location = useLocation();
  const sessionId = location.search.replace('?session_id=', '');

  useEffect(() => {
    async function fetchSession() {
      await fetch('/api/checkout-session?sessionId=' + sessionId).then((res) =>
        res.json()
      );
    }
    fetchSession();
  }, [sessionId]);

  return (
    <div className="sr-root">
      <div className="sr-main">
        <header className="sr-header">
          <div className="sr-header__logo"></div>
        </header>
        <div className="sr-payment-summary completed-view">
          <h1>Your payment succeeded</h1>

          <p>
            Please login with same email you put on the course apply page.
          </p>
          <p>
            If you're signing with the 'Continue with Email' option, please use the 'Email me a link to sign in' option.
            Then after sign in, you can update your password in your account settings.
          </p>
          <img
            alt="login with email"
            src="https://mf-enki-media-1.s3.eu-west-3.amazonaws.com/email1.png"
            width="450"
            height="500"
          />
        <br />

        <a href={process.env.REACT_APP_LOGIN_URL}>Click here to head to the login page</a>

        </div>
      </div>
      <div className="sr-content">
        <div className="pasha-image-stack">
          <img
            alt=""
            src="https://picsum.photos/280/320?random=1"
            width="140"
            height="160"
          />
          <img
            alt=""
            src="https://picsum.photos/280/320?random=2"
            width="140"
            height="160"
          />
          <img
            alt=""
            src="https://picsum.photos/280/320?random=3"
            width="140"
            height="160"
          />
          <img
            alt=""
            src="https://picsum.photos/280/320?random=4"
            width="140"
            height="160"
          />
        </div>
      </div>
    </div>
  );
};

export default Success;
