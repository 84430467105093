import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const formatPrice = ({ amount, currency }) => {
  const numberFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
  });

  const total = amount.toFixed(2);
  return numberFormat.format(total);
};

const Checkout = () => {
  const query = new URLSearchParams(useLocation().search);
  const [currency, setCurrency] = useState('USD');
  const [courseId, setCourseId] = useState(query.get('course_id'));
  const [applicantId, setApplicantId] = useState(query.get('applicant_id'));
  const [email, setEmail] = useState(query.get('email'));

  const [courseName, setCourseName] = useState('');
  const [prices, setPrices] = useState([]);

  const pay = async (id) => {
    try {
      const price = prices.find(p => p.id === id)
  
      const result = await fetch("/api/create-checkout-session", {
        method: 'POST',
        body: JSON.stringify({
          email,
          applicantId,
          priceId: price.price_id,
          paymentType: price.payment_type.toUpperCase()
        }),
        headers: {
          'Content-type': 'application/json',
        },
      }).then(r => r.json())
  
      window.location.href = result.url;
    } catch (error) {
      alert(error.message);
    }
  }

  useEffect(() => {
    async function fetchConfig() {
      const result = await fetch(`/api/setup?courseId=${courseId}`).then(r => r.json());
      setCourseName(result[0]?.course_name);
      setPrices(result);
    }
    fetchConfig();
  }, []);

  return (
    <div className="sr-root">
      <section className="container">
        <h1>{ courseName }</h1>
      </section>
      <div className="sr-main">

      {prices.map((price, i) => (
        <section className="container" key={price.id}>
        <div>
          <h1>{ price.price_name }</h1>
          <h4>{ price.payment_type === "one_time" ? "Pay one time and get access forever" : "Recurring till you finish the course" }</h4>
          <div className="pasha-image">
            <img
              alt={ price.price_name }
              src={ price.img_url }
              width="180"
              height="160"
            />
          </div>
        </div>
          <button role="link" type="button" onClick={() => pay(price.id)}>
            Pay {formatPrice({amount: price.price, currency})}
          </button>
      </section>
      ))}
      </div>
    </div>
  );
};

export default Checkout;
